<template>
  <div class="p-10">
    <v-alert
      dismissible
      type="success"
      class=" mb-5"
      :show="successAlertMessage != '' && 7000"
    >
      {{ successAlertMessage }}
    </v-alert>
    <v-alert
      dismissible
      type="error"
      class=" mb-5"
      :show="dangerAlertMessage != '' && 7000"
    >
      {{ dangerAlertMessage }}
    </v-alert>
    <div class="bg-white py-4 px-8">
      <div class="flex justify-between space-x-2 items-center border-b py-4">
        <div class="w-1/2">
          <span class="text-blue-active font-medium text-xl"
            >Liste des produits</span
          >
        </div>
        <div class="hidden lg:flex items-start space-x-4">
          <div
            v-if="userIsAuthorized"
            style="background-color: #51aa1c"
            class="
              truncate
              cursor-pointer
              py-2
              rounded-sm
              flex
              items-center
              w-40
              px-4
              space-x-3
              lg:w-full
            "
            @click="createProduct()"
          >
            <div>
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M13 5V8H8V13H5V8H0V5H5V0H8V5H13Z" fill="white" />
              </svg>
            </div>
            <div class="">
              <a
                class="text-white font-semibold cursor-pointer"
                style="font-size: 14px"
                >Ajouter un nouveau produit
              </a>
            </div>
          </div>
          <div class="w-1/2">
            <v-input
              v-model="search"
              small
              left-icon="search"
              placeholder="Rechercher..."
              class="w-64"
            />
          </div>
        </div>
      </div>
      <table class="py-4 overflow-x-auto min-w-full ">
        <thead>
          <tr class="p-2 bg-gray-200" style="color: #5f738c">
            <td class="p-2">
              <span class="font-semibold">Nom du produit</span>
            </td>
            <td><span class="font-semibold">Numéro du produit </span></td>
            <td><span class="font-semibold">Catégorie du produit </span></td>
            <td v-if="userIsAuthorized">
              <span class="font-semibold">Action </span>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr
            @click="onGotoV1Click"
            class="px-2 py-4 border-b hover:bg-gray-300 cursor-pointer"
            style="color: #5f738c"
          >
            <td class="py-4 px-2">
              <span class="font-semibold"> Microcrédit Alafia V1</span>
            </td>
            <td>
              <span class="font-semibold">1</span>
            </td>
            <td>
              <span class="font-semibold">Microcrédit Alafia </span>
            </td>
            <td></td>
          </tr>
          <tr
            class="px-2 py-4 border-b hover:bg-gray-300 cursor-pointer"
            v-for="product in products"
            :key="product.id"
            @click="chooseProduct(product)"
            style="color: #5f738c"
          >
            <td class="py-4 px-2">
              <span class="font-semibold"> {{ product.name }} </span>
            </td>
            <td>
              <span class="font-semibold">{{ product.productNumber }} </span>
            </td>
            <td>
              <span class="font-semibold"
                >{{ product.category ? product.category.name : product.name }}
              </span>
            </td>
            <td v-if="userIsAuthorized">
              <div v-if="!product.url" class="flex space-x-2">
                <a class="cursor-pointer" @click="edit(product, $event)">
                  <div class="p-1 rounded" style="background-color: #1565b1">
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.77 11.3896H0.73C0.4645 11.3896 0.25 11.5996 0.25 11.8594V12.3879C0.25 12.4525 0.304 12.5054 0.37 12.5054H12.13C12.196 12.5054 12.25 12.4525 12.25 12.3879V11.8594C12.25 11.5996 12.0355 11.3896 11.77 11.3896ZM2.4355 10.1564C2.4655 10.1564 2.4955 10.1535 2.5255 10.1491L5.0485 9.716C5.0785 9.71013 5.107 9.69692 5.128 9.6749L11.4865 3.45168C11.5004 3.4381 11.5114 3.42197 11.519 3.40421C11.5265 3.38645 11.5304 3.36741 11.5304 3.34818C11.5304 3.32895 11.5265 3.30992 11.519 3.29216C11.5114 3.2744 11.5004 3.25826 11.4865 3.24468L8.9935 0.803256C8.965 0.775362 8.9275 0.760681 8.887 0.760681C8.8465 0.760681 8.809 0.775362 8.7805 0.803256L2.422 7.02647C2.3995 7.04849 2.386 7.07492 2.38 7.10428L1.9375 9.5736C1.92291 9.65224 1.92812 9.73319 1.95269 9.80942C1.97726 9.88565 2.02044 9.95487 2.0785 10.0111C2.1775 10.105 2.302 10.1564 2.4355 10.1564Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </a>
                <a
                  class="cursor-pointer"
                  @click="showSupModal(product, $event)"
                >
                  <div class="p-1 rounded bg-danger">
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.8125 2.47654H9.0625V1.37548C9.0625 0.889636 8.67012 0.494629 8.1875 0.494629H3.8125C3.32988 0.494629 2.9375 0.889636 2.9375 1.37548V2.47654H1.1875C0.945508 2.47654 0.75 2.67336 0.75 2.91697V3.35739C0.75 3.41795 0.799219 3.4675 0.859375 3.4675H1.68516L2.02285 10.6657C2.04473 11.135 2.43027 11.5053 2.89648 11.5053H9.10352C9.57109 11.5053 9.95527 11.1364 9.97715 10.6657L10.3148 3.4675H11.1406C11.2008 3.4675 11.25 3.41795 11.25 3.35739V2.91697C11.25 2.67336 11.0545 2.47654 10.8125 2.47654ZM8.07812 2.47654H3.92188V1.48559H8.07812V2.47654Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="supModal && userIsAuthorized"
        class="
                overflow-x-hidden overflow-y-auto
                fixed
                inset-0
                z-50
                outline-none
                focus:outline-none
                justify-center
                items-center
                flex
              "
      >
        <div class="relative w-auto my-6 mx-auto" style="width: 500px">
          <!--content-->
          <div
            class="
                    shadow-lg
                    relative
                    flex flex-col
                    w-full
                    bg-white
                    outline-none
                    focus:outline-none
                  "
          >
            <!--header-->
            <div
              class="bg-blue-dark flex items-center justify-between p-5"
              style="height: 61px"
            >
              <h3 class="font-semibold text-white">
                Suppression du produit {{ selectedProduct.name }}
              </h3>
              <button
                class="
                        p-1
                        ml-auto
                        bg-transparent
                        border-0
                        text-black
                        opacity-5
                        float-right
                        text-3xl
                        leading-none
                        font-semibold
                        outline-none
                        focus:outline-none
                      "
                v-on:click="closeSup()"
              >
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.70703 0.792969L0.292969 2.20703L6.58594 8.5L0.292969 14.793L1.70703 16.207L8 9.91406L14.293 16.207L15.707 14.793L9.41406 8.5L15.707 2.20703L14.293 0.792969L8 7.08594L1.70703 0.792969Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
            <!--body-->
            <div class="relative p-10 flex-auto space-y-4">
              <div>
                <span>Vous êtes sur le point de supprimer ce produit </span>
              </div>
              <div class="flex items-center justify-between space-x-2">
                <button
                  class="
                          text-gray-700
                          w-1/2
                          border-2 border-gray-400
                          hover:bg-red-500
                          font-bold
                          text-sm
                          px-6
                          py-3
                          rounded
                          hover:bg-gray-100
                          outline-none
                          focus:outline-none
                          mr-1
                          mb-1
                          ease-linear
                          transition-all
                          duration-150
                        "
                  type="button"
                  v-on:click="closeSup()"
                >
                  Annuler
                </button>
                <button
                  class="
                          w-1/2
                          text-white
                          rounded
                          bg-danger
                          font-bold
                          uppercase
                          px-6
                          py-3
                          text-sm
                          outline-none
                          focus:outline-none
                          mr-1
                          mb-1
                          ease-linear
                          transition-all
                          duration-150
                        "
                  type="button"
                  v-on:click="supCat()"
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="supModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  </div>
</template>

<script>
import Product from '../../../../store/models/Product'
import Category from '../../../../store/models/Category'
import { mapGetters } from 'vuex'
import { AUTH_ACCESS_TOKEN_KEY } from '@/constants'

export default {
  data() {
    return {
      isSFD: false,
      successAlertMessage: '',
      dangerAlertMessage: '',
      createModal: false,
      selectedProduct: {},
      supModal: false,
      name: '',
      numero: '',
      categorie: '',
      showModal: false,
      search: ''
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),

    products() {
      if (this.isSFD) {
        const organizationId = this.user.organizationId
        if (this.search != '')
          return Product.query()
            .where(product => {
              return (
                (product.name.includes(this.search) ||
                  product.productNumber.includes(this.search)) &&
                product.sfdConcern.includes(organizationId)
              )
            })
            .with('category')
            .orderBy('name')
            .get()
        else
          return Product.query()
            .where(product => {
              return product.sfdConcern.includes(organizationId)
            })
            .with('category')
            .orderBy('name')
            .get()
      } else {
        if (this.search != '')
          return Product.query()
            .where(product => {
              return (
                product.name.includes(this.search) ||
                product.productNumber.includes(this.search)
              )
            })
            .with('category')
            .orderBy('name')
            .get()
        else
          return Product.query()
            .with('category')
            .orderBy('name')
            .all()
      }
    },

    userIsAuthorized() {
      if (
        (this.user.role == 'fnm:admin' || this.user.role == 'fnm:superadmin') &&
        !this.isSFD
      )
        return true
      return false
    }
  },
  methods: {
    onGotoV1Click() {
      window.location.href =
        process.env.VUE_APP_V1_BASE_URL +
        '?t=' +
        sessionStorage.getItem(AUTH_ACCESS_TOKEN_KEY)
    },

    chooseProduct(product) {
      if (product.url?.length > 0) {
        window.location.href =
          product.url + '?t=' + sessionStorage.getItem(AUTH_ACCESS_TOKEN_KEY)
      } else {
        this.$router.push({
          name: 'dashboard',
          params: { productId: product.id }
        })
      }
    },
    modifProcuct(event) {
      console.log(event)
    },
    edit(product, event) {
      this.successAlertMessage = ''
      this.dangerAlertMessage = ''
      console.log('eeeeeee')
      event.stopPropagation()
      this.$router.push({
        name: 'modifProduct',
        params: { productId: product.id }
      })
    },
    showSupModal(product, event) {
      event.stopPropagation()
      this.selectedProduct = product
      this.supModal = true
    },
    supCat() {
      this.successAlertMessage = ''
      this.dangerAlertMessage = ''
      Product.api()
        .remove(this.selectedProduct.id)
        .then(response => {
          if (response.response.status == 200) {
            Product.delete(this.selectedProduct.id)
            this.successAlertMessage = `Le produit ${this.selectedProduct.name} a été supprimé avec succès`
          }
        })
        .catch(error => {
          if (error.response.data.error == 'existing creditline') {
            this.dangerAlertMessage =
              'Impossible de supprimer ce produit. Il a été utilisé dans des dispatchings'
          }
          if (error.response.data.error == 'product account credited') {
            this.dangerAlertMessage =
              'Impossible de supprimer ce produit. Le compte fnm du produit est crédité'
          }
        })
      this.closeSup()
    },
    close() {
      this.showModal = false
    },
    closeCreateModal() {
      this.createModal = false
    },
    closeSup() {
      this.supModal = false
    },
    createProduct() {
      console.log('rr')
      this.$router.push({
        name: 'createProduct'
      })
    }
  },
  created() {
    if (this.$route.meta.isSFD) this.isSFD = true
    this.successAlertMessage = this.$route.params.successAlertMessage
      ? this.$route.params.successAlertMessage
      : ''
    this.dangerAlertMessage = this.$route.params.dangerAlertMessage
      ? this.$route.params.dangerAlertMessage
      : ''
    Product.api().fetchAll()
    Category.api().fetchAll()
  }
}
</script>

<style></style>
